<template>
  <div id="uploadStockPreorderAndRedText" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow bg-white rounded">
        <div class="row m-0 p-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">OfficeMate Manage Product</p>
          </div>
        </div>
        <div class="row m-0 p-2">
          <div class="col-12 ">
            <b-tabs content-class="mt-2 m-0 p-0">
              <b-tab title="Product List">
                <TableProductList :key="tableKey" :shopObject="shopObject" />
              </b-tab>
              <b-tab title="Upload Products">
                <UploadProduct
                  @uploadSuccess="reloadTable"
                  :shopObject="shopObject"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableProductList from "@/components/MarketPlace/OfficeMateManageProduct/TableProductList.vue";
import UploadProduct from "@/components/MarketPlace/OfficeMateManageProduct/UploadProduct.vue";

export default {
  name: "UploadStockPreorderAndRedText",
  components: {
    TableProductList,
    UploadProduct,
  },
  data() {
    return {
      tableKey: 0,
      shopObject: [
        {
          id: 1,
          name: "60043 Standard-Product-Active",
          key: { shopId: 60043, isActive: true },
        },
        {
          id: 2,
          name: "60043 Standard-Product-Inactive",
          key: { shopId: 60043, isActive: false },
        },
      ],
    };
  },
  methods: {
    reloadTable() {
      // Incrementing the tableKey to force re-rendering of the table component
      this.tableKey++;
    },
  },
};
</script>

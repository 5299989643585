<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-0 ">
      <div class="col-12 col-lg-12 m-0 p-0 shadow bg-white rounded">
        <div class="row m-0 p-2">
          <div class="col-12 col-lg-6">
            <div class="input-group input-group-sm">
              <div
                v-for="(shop, index) in shopObject"
                :key="index"
                class="input-group-prepend input-group-sm "
              >
                <button
                  :class="selectedShop == shop.id ? 'bt-main' : ''"
                  @click="selectBU(shop)"
                  class="btn btn-sm btn-outline-secondary m-2 w-100"
                >
                  {{ shop.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 p-2">
          <div class="col-12 col-lg-6">
            <div class="input-group input-group-sm m-2">
              <div class="input-group-prepend input-group-sm ">
                <select class="custom-select" v-model="mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <input
                type="search"
                class="form-control"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="searchValueFromObject()"
              />
              <button
                type="button"
                class="btn btn-sm bt-main "
                @click="searchValueFromObject()"
              >
                <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
              </button>
            </div>
          </div>
        </div>
        <div class="row m-0 pl-4 pb-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
              จำนวน &nbsp;
              <span class="text-main">
                {{ new Intl.NumberFormat().format(totalRows) }}
              </span>
              <span class="ml-2">รายการ</span>
            </p>
            <div v-if="isLoading" class="col-12 col-xl-12 m-0 p-0 text-center">
              <div class="p-xl-5 m-xl-5">
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
              </div>
            </div>
            <div v-else>
              <b-table
                hover
                outlined
                show-empty
                class="form-control-sm font-0-7s"
                :items="items"
                :empty-text="'ไม่พบข้อมูล / No Data'"
                head-variant="light"
                :current-page="page_num"
                :per-page="page_size"
                :fields="fields"
              >
                <template #cell(ลำดับที่)="row">
                  <div>
                    {{ new Intl.NumberFormat().format(row.item["ลำดับที่"]) }}
                  </div>
                </template>

                <template #cell(จำนวนสินค้า)="row">
                  <div>
                    {{
                      new Intl.NumberFormat().format(row.item["จำนวนสินค้า"])
                    }}
                  </div>
                </template>

                <template #cell(ราคาเต็ม)="row">
                  <div>
                    {{ new Intl.NumberFormat().format(row.item["ราคาเต็ม"]) }}
                  </div>
                </template>
              </b-table>
            </div>

            <div class="col-12">
              <div class="row justify-content-end">
                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                  <select class="custom-select" v-model="page_size">
                    <option
                      v-for="(e, i) in page_size_list"
                      :key="i"
                      :value="e"
                    >
                      {{ e }}
                    </option>
                  </select>
                </div>
                <div class="col-6 col-xl-3">
                  <b-pagination
                    v-model="page_num"
                    :total-rows="totalRows"
                    :per-page="page_size"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableProductList",
  props: {
    shopObject: Array,
  },
  data() {
    return {
      mode: "รหัสสินค้า",
      find: "",
      isLoading: false,
      items: [],
      itemlist: [],
      totalRows: 0,
      page_size: 25,
      page_size_list: [25, 50, 100],
      page_num: 1,
      fields: [
        { key: "ลำดับที่", label: "ลำดับที่", sortable: true },
        { key: "รหัสสินค้า", label: "รหัสสินค้า", sortable: true },
        {
          key: "รหัสสินค้า Seller",
          label: "รหัสสินค้า Seller",
          sortable: true,
        },
        { key: "ชื่อสินค้า", label: "ชื่อสินค้า", sortable: true },
        { key: "จำนวนสินค้า", label: "จำนวนสินค้า", sortable: true },
        { key: "ราคาเต็ม", label: "ราคาเต็ม", sortable: true },
        { key: "ราคาขายรวม VAT", label: "ราคาขายรวม VAT", sortable: true },
        {
          key: "ราคาขายไม่รวม VAT",
          label: "ราคาขายไม่รวม VAT",
          sortable: true,
        },
        { key: "สถานะ", label: "สถานะ", sortable: true },
      ],
      select_mode: [
        { key: "รหัสสินค้า", name: "รหัสสินค้า" },
        { key: "รหัสสินค้า Seller", name: "รหัสสินค้า Seller" },
        { key: "สถานะ", name: "สถานะ" },
      ],
      selectedShop: 1,
      selectedShopObject: {
        id: 1,
        name: "60043 Standard-Product-Active",
        key: { shopId: 60043, isActive: true },
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.initTable();
    this.isLoading = false;
  },
  methods: {
    async initTable() {
      try {
        const payload = {
          shopId: this.selectedShopObject.key.shopId,
          isActive: this.selectedShopObject.key.isActive,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `market-place/officemate/getProductList`,
          payload,
          1
        );
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
        this.totalRows = this.items ? this.items.length : 0;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async selectBU(shop) {
      this.selectedShop = shop.id;
      this.selectedShopObject = shop;
      await this.initTable();
    },
    async searchValueFromObject() {
      try {
        if (this.items) {
          this.items = this.itemlist.filter((item) => {
            const modeValue = item[this.mode];
            if (typeof modeValue === "string") {
              return modeValue
                .toLowerCase()
                .includes(this.find.toString().toLowerCase());
            }
            return false;
          });
          this.totalRows = this.items.length;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async unSearchValueFromObject() {
      this.items = this.itemlist;
      this.totalRows = this.items?.length ?? 0;
    },
  },
  watch: {
    find: function(v) {
      if (v === "") {
        this.unSearchValueFromObject();
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-0 justify-content-center">
      <div class="col-12 col-lg-12 m-0 p-0 shadow bg-white rounded">
        <div class="row m-0 p-2">
          <div class="col-12 col-lg-6">
            <div class="input-group input-group-sm">
              <div
                v-for="(shop, index) in shopObject"
                :key="index"
                class="input-group-prepend input-group-sm"
              >
                <button
                  :class="selectedShop == shop.id ? 'bt-main' : ''"
                  @click="selectBU(shop)"
                  class="btn btn-sm btn-outline-secondary m-2 w-100"
                >
                  {{ shop.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 p-4">
          <div class="col-12 col-xl-4 mb-3">
            <b-form-file
              accept=".xlsx , .xls"
              v-model="file1"
              :state="Boolean(file1)"
              size="sm"
              placeholder="เลือกไฟล์ที่ต้องการอัพโหลด"
              drop-placeholder="Drop file here..."
              @change="onChange"
            ></b-form-file>
          </div>
          <div class="col-12 col-xl-4 mb-2">
            <button type="button" class="btn btn-sm bt-main" @click="Upload()">
              อัพโหลดข้อมูล
            </button>
            <button
              type="button"
              class="ml-1 btn-sm btn bt-TWD"
              @click="ClearData()"
            >
              ยกเลิก
            </button>
            <button type="button" class="ml-1 btn btn-sm bt-BNB">
              <a download class="text-white" @click="SampleFile()">
                ดาวน์โหลดไฟล์
              </a>
            </button>
          </div>
        </div>
        <div class="row m-0 pl-4 pb-4">
          <div class="col-12 mx-0 mb-3 mb-xl-0">
            <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
              จำนวน &nbsp;
              <span class="text-main">
                {{ new Intl.NumberFormat().format(totalRows) }}
              </span>
              <span class="ml-2">รายการ</span>
            </p>
            <div v-if="isLoading" class="col-12 col-xl-12 m-0 p-0 text-center">
              <div class="p-xl-5 m-xl-5">
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
                <b-spinner variant="info" small type="grow"></b-spinner>
              </div>
            </div>
            <div v-else>
              <b-table
                hover
                outlined
                show-empty
                class="form-control-sm font-0-7s"
                :items="items"
                :fields="fields"
                head-variant="light"
                :current-page="page_num"
                :per-page="page_size"
              >
              </b-table>
            </div>
            <div class="col-12">
              <div class="row justify-content-end">
                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                  <select class="custom-select" v-model="page_size">
                    <option
                      v-for="(e, i) in page_size_list"
                      :key="i"
                      :value="e"
                    >
                      {{ e }}
                    </option>
                  </select>
                </div>
                <div class="col-6 col-xl-3">
                  <b-pagination
                    v-model="page_num"
                    :total-rows="totalRows"
                    :per-page="page_size"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import XLSX from "xlsx";

export default {
  name: "OFM_UploadProducts",
  props: {
    shopObject: Array,
  },
  data() {
    return {
      file1: null,
      items: [],
      fields: [
        { key: "ลำดับที่", label: "ลำดับที่" },
        { key: "รหัส Seller", label: "รหัส Seller" },
        { key: "รหัสสินค้า", label: "รหัสสินค้า" },
        { key: "รหัสสินค้า Seller", label: "รหัสสินค้า Seller" },
        { key: "ชื่อสินค้า", label: "ชื่อสินค้า" },
        { key: "จำนวนสินค้า", label: "จำนวนสินค้า" },
        { key: "ราคาเต็ม", label: "ราคาเต็ม" },
        { key: "ราคาขายรวม VAT", label: "ราคาขายรวม VAT" },
        { key: "ราคาขายไม่รวม VAT", label: "ราคาขายไม่รวม VAT" },
        { key: "สถานะ", label: "สถานะ" },
      ],
      page_size: 10,
      page_size_list: [10, 20, 50, 100],
      page_num: 1,
      totalRows: 0,
      isLoading: false,
      selectedShop: 1,
      selectedShopObject: {
        id: 1,
        name: "60043 Standard-Product-Active",
        key: { shopId: 60043, isActive: true },
      },
    };
  },
  methods: {
    async selectBU(shop) {
      this.selectedShop = shop.id;
      this.selectedShopObject = shop;
      this.ClearData();
    },
    async onChange() {
      try {
        this.file = event.target.files ? event.target.files[0] : null;
        let fname = this.file.name;
        fname = fname.toLowerCase();
        if (fname.indexOf(".xls") < 0) {
          throw `กรุณาตรวจสอบ format ไฟล์!!! file  ${fname}  is not excel file (.xls or .xlsx).`;
        }
        if (this.file) {
          const reader = new FileReader();
          this.isLoading = true;
          reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            let rows = XLSX.utils.sheet_to_row_object_array(ws);

            var jsonData = rows.map((row) => ({
              "รหัส Seller": this.selectedShopObject.key.shopId,
              ลำดับที่: row["ลำดับที่"],
              รหัสสินค้า: row["รหัสสินค้า"],
              "รหัสสินค้า Seller": row["รหัสสินค้า Seller"],
              ชื่อสินค้า: row["ชื่อสินค้า"],
              จำนวนสินค้า: row["จำนวนสินค้า"],
              ราคาเต็ม: row["ราคาเต็ม"],
              "ราคาขายรวม VAT": row["ราคาขายรวม VAT"],
              "ราคาขายไม่รวม VAT": row["ราคาขายไม่รวม VAT"],
              สถานะ: row["สถานะ"],
            }));

            let findBlanks = jsonData.find(
              (itm) =>
                itm["รหัสสินค้า Seller"] == "" ||
                itm["รหัสสินค้า Seller"] == "undefined" ||
                itm["รหัสสินค้า Seller"] == "#VALUE!" ||
                itm["รหัสสินค้า"] == "" ||
                itm["รหัสสินค้า"] == "undefined" ||
                itm["รหัสสินค้า"] == "#VALUE!" ||
                itm["ราคาเต็ม"] == "" ||
                itm["ราคาเต็ม"] == "undefined" ||
                itm["ราคาเต็ม"] == "#VALUE!" ||
                itm["ราคาขายรวม VAT"] == "" ||
                itm["ราคาขายรวม VAT"] == "undefined" ||
                itm["ราคาขายรวม VAT"] == "#VALUE!" ||
                itm["ราคาขายไม่รวม VAT"] == "" ||
                itm["ราคาขายไม่รวม VAT"] == "undefined" ||
                itm["ราคาขายไม่รวม VAT"] == "#VALUE!" ||
                itm["สถานะ"] == "" ||
                itm["สถานะ"] == "undefined" ||
                itm["สถานะ"] == "#VALUE!"
            );
            if (findBlanks) {
              this.$serviceMain.showErrorAlert(
                this,
                `กรุณาตรวจสอบไฟล์ พบค่าว่าง ค่า null ค่า #VALUE! ใน skCode, System, isActive `
              );
            }

            this.items = jsonData;
            this.totalRows = this.items.length;
            this.isLoading = false;
          };
          reader.readAsBinaryString(this.file);
          this.isLoading = false;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async ClearData() {
      this.file1 = null;
      this.items = null;
      this.itemlist = null;
      this.totalRows = 0;
    },
    async Upload() {
      try {
        if (this.items == null || this.items.length == 0) {
          throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลดก่อน / Please select file to upload first`;
        }

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการอัพโหลดสินค้าของร้าน ${this.selectedShop} ใช่หรือไม่? / Do you want to upload products of shop ${this.selectedShop}?`
        );

        if (!confirm) return;

        const payload = {
          shopId: this.selectedShopObject.key.shopId,
          isActive: this.selectedShopObject.key.isActive,
          jsonData: this.items,
        };

        await this.$serviceAPI.call_API(
          "post",
          `market-place/officemate/uploadProducts`,
          payload,
          1
        );
        this.$serviceMain.showSuccessAlert(
          this,
          "อัพโหลดข้อมูลสำเร็จ / Complete"
        );
        this.$emit("uploadSuccess");
        await this.ClearData();
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async SampleFile() {
      try {
        const payload = {
          shopId: this.selectedShopObject.key.shopId,
          isActive: this.selectedShopObject.key.isActive,
        };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `market-place/officemate/getProductList`,
          payload,
          1
        );
        /// replace , to blank
        if (getAPI && getAPI.data) {
          const template = getAPI.data.map((itm) => ({
            "รหัส Seller": this.selectedShopObject.key.shopId,
            ลำดับที่: itm["ลำดับที่"],
            รหัสสินค้า: itm["รหัสสินค้า"],
            "รหัสสินค้า Seller": itm["รหัสสินค้า Seller"],
            ชื่อสินค้า: itm["ชื่อสินค้า"],
            จำนวนสินค้า: itm["จำนวนสินค้า"],
            ราคาเต็ม: itm["ราคาเต็ม"],
            "ราคาขายรวม VAT": itm["ราคาขายรวม VAT"],
            "ราคาขายไม่รวม VAT": itm["ราคาขายไม่รวม VAT"],
            สถานะ: itm["สถานะ"],
          }));
          this.$serviceMain.JsonToExcat(
            template,
            "Template_OFM_Upload_Products.xlsx"
          );
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>
